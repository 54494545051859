/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://m1oh4333jf.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "moderationNotifier",
            "endpoint": "https://kxhaeqk8ul.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "notifications",
            "endpoint": "https://krdgyctcr3.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "stripePayments",
            "endpoint": "https://x8p1hq46zd.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "twilio",
            "endpoint": "https://cdgws1mzgc.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://54d5ggarm5ejdhr72s4fq3eepy.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-jc6st3ilwndktp3sm6p62mojoi",
    "aws_cognito_identity_pool_id": "us-east-1:cb0c9a24-4067-43c0-81b0-8cfbff9034dc",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_HE83JEz89",
    "aws_user_pools_web_client_id": "70242hq1pa0cng1lus33ij10s5",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME",
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "heavenlymatchstorage00616-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
