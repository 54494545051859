export const EmailTemplates = {
  SUSPEND: 'HEAVENLYMATCH_ACCOUNT_SUSPEND_TEMPLATE',
  ENABLE: 'HEAVENLYMATCH_ACCOUNT_ENABLE_TEMPLATE',
  ANNOUNCEMENT: 'HEAVENLYMATCH_ANNOUNCEMENT_EMAIL_TEMPLATE',
  BIO_UPDATE_REJECTION: 'HEAVENLYMATCH_BIO_REJECTION_EMAIL_TEMPLATE',
  PROFILE_REJECTION: 'HEAVENLYMATCH_PROFILE_REJECTION_EMAIL_TEMPLATE',
  IMAGE_UPDATE_REJECTION: 'HEAVENLYMATCH_IMAGE_REJECTION_EMAIL_TEMPLATE',
  IMAGE_UPDATE_APPROVAL: 'HEAVENLYMATCH_IMAGE_APPROVAL_EMAIL_TEMPLATE',
  BIO_APPROVAL: 'HEAVENLYMATCH_BIO_APPROVED_EMAIL_TEMPLATE',
  PROFILE_SUSPEND: 'HEAVENLYMATCH_SUSPEND_USER_TEMPLATE',
  DISMISS_PROFILE_SUSPEND: 'HEAVENLYMATCH_DISMISS_SUSPEND_USER_EMAIL_TEMPLATE',
  INVITE_CANDIDATE_EMAIL: 'HEAVENLYMATCH_INVITE_CANDIDATE_EMAIL_TEMPLATE',
  EXCLUDED_FROM_PAYMENT_TEMPLATE:
    'HEAVENLYMATCH_EXCLUDED_FROM_PAYMENT_TEMPLATE',
  EXCLUDED_FROM_PAYMENT_AFTER_APPROVE_TEMPLATE:
    'HEAVENLYMATCH_EXCLUDED_FROM_PAYMENT_AFTER_APPROVE_TEMPLATE',
};
