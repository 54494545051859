import React from 'react';
import { Pagination, Table } from 'antd';
import { EditableCell } from '../../utilities/antd';

const HeavenlyMatchTable = ({
  columns,
  dataSource,
  rowSelection = '',
  rowKey,
  rowClassName,
  loading,
  count,
  isCountLoading,
  currentPage,
  setCurrentPage,
  isSearch,
}) => {
  return (
    <>
      <Table
        className="w-full shadow-sm"
        pagination={{
          pageSize: 10,
          showSizeChanger: false,
          style: { display: isSearch ? 'flex' : 'none', justifyContent: 'end' },
        }}
        scroll={{
          scrollToFirstRowOnChange: true,
          x: 'max-content',
        }}
        columns={columns}
        dataSource={!loading && dataSource}
        rowSelection={rowSelection}
        rowKey={rowKey}
        loading={loading}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        rowClassName={rowClassName}
      />
      {!isSearch ? (
        <div
          style={{ display: 'flex', justifyContent: 'end', margin: '8px 0px' }}
        >
          <Pagination
            align="end"
            defaultCurrent={currentPage}
            current={currentPage}
            defaultPageSize={10}
            showSizeChanger={false}
            onChange={(page) => {
              setCurrentPage(page);
            }}
            total={isCountLoading ? 80 : count}
          />
        </div>
      ) : null}
    </>
  );
};

export default HeavenlyMatchTable;
