import { Form, Button, Input, Popover } from 'antd';
import {
  FunnelPlotOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { teamConfig } from './CandidateManagementTableConfig';
import './CandidateManagementTable.css';
import {
  listHeavenlymatchApprovedCandidatesPaginated,
  countHeavenlymatchUsers,
  searchHeavenlymatchApprovedCandidatesPaginated,
} from '../../shared/api/candidates';
import { candidateModerationStatus } from '../../enum';
import HeavenlyMatchTable from '../../shared/Table/HeavenlyMatchTable';
import InviteCandidateModal from '../InviteCandidateModal/InviteCandidateModal';
import AuthContext from '../../shared/context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { isMobileScreen } from '../../utilities';
import {
  ALL,
  EXCLUDED_FROM_PAYMENT,
  FILTER_LABEL,
  FREE_CANDIDATE,
  GroupType,
  PAID_CANDIDATE,
} from '../../enum';
import { ABOUT_ME } from '../../shared/routes';
import FilterPaymentTypography from '../../shared/components/FilterPaymentTypography/FilterPaymentTypography';

const CandidateManagementTable = ({ activeTabKey }) => {
  const mobileScreen = isMobileScreen();
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterTitle, setFilterTitle] = useState(null);
  const [dataSource, setDataSource] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState();
  const [isCountLoading, setIsCountLoading] = useState(false);
  const currentUserGroup = JSON.parse(localStorage.getItem('userGroup'));
  const [paymentFilteredDataSource, setPaymentFilteredDataSource] =
    useState(dataSource);
  const [serachFilteredDataSource] =
    useState(dataSource);
  const [isPaymentFilter, setIsPaymentFilter] = useState(false);
  const [isSearchFilter, setIsSearchFilter] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const debounceTimeout = useRef(null); // Ref to store timeout ID
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  // Fetching data from dynamo
  useEffect(() => {
    if (activeTabKey !== 'candidates') {
      setFilterTitle(null);
      setIsPaymentFilter(false);
      setPaymentFilteredDataSource(null);
    }
  }, [activeTabKey]);
  const defaultFilters = {
    and: [
      {
        newProfileApproved: {
          ne: [candidateModerationStatus.PENDING, candidateModerationStatus.REJECTED],
        },
      },
      {
        isReported: { ne: true },
      },
    ],
  };
  
  const content = (
    <div>
      <FilterPaymentTypography
        label={PAID_CANDIDATE}
        onClick={() => {
          const filteredData = dataSource?.filter(
            (item) => item.paymentExclusion === false && item.isPaid === true
          );
          setFilterTitle(PAID_CANDIDATE);
          setPaymentFilteredDataSource(filteredData);
          setIsSearchFilter(false);
          setIsPaymentFilter(true);
          setOpen(false);
        }}
      />
      <FilterPaymentTypography
        label={EXCLUDED_FROM_PAYMENT}
        onClick={() => {
          const filteredData = dataSource?.filter(
            (item) => item.paymentExclusion === true && item.isPaid === false
          );
          setFilterTitle(EXCLUDED_FROM_PAYMENT);
          setPaymentFilteredDataSource(filteredData);
          setIsSearchFilter(false);
          setIsPaymentFilter(true);
          setOpen(false);
        }}
      />
      <FilterPaymentTypography
        label={FREE_CANDIDATE}
        onClick={() => {
          const filteredData = dataSource?.filter(
            (item) => item.paymentExclusion === false && item.isPaid === false
          );
          setFilterTitle(FREE_CANDIDATE);
          setPaymentFilteredDataSource(filteredData);
          setIsSearchFilter(false);
          setIsPaymentFilter(true);
          setOpen(false);
        }}
      />
      <FilterPaymentTypography
        label={ALL}
        onClick={() => {
          setFilterTitle(ALL);
          setPaymentFilteredDataSource(dataSource);
          setIsSearchFilter(false);
          setIsPaymentFilter(true);
          setOpen(false);
        }}
      />
    </div>
  );

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const handleClick = () => {
    setIsModalOpen(true);
  };

  const onView = (record) => {
    window.localStorage.setItem('userDynamo', JSON.stringify(record));
    authContext.setUserDynamo(record);
    navigate(ABOUT_ME(record.id));
  };

  // Fetching team member column list.
  const columns = teamConfig(onView);
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        inputType: col.inputType,
        inputProps: col.inputProps,
      }),
    };
  });
  // Fetching data from dynamo
  useEffect(() => {
    countHeavenlymatchUsers(setCount, setIsCountLoading);
  }, []); // Empty dependency array to ensure it runs only on mount
  useEffect(() => {
    const getUserDetails = async () => {
      setIsLoading(true);
      try {
        const defaultFilters = {
          and: [
            {
              newProfileApproved: {
                ne: [candidateModerationStatus.PENDING, candidateModerationStatus.REJECTED],
              },
            },
            {
              isReported: { ne: true },
            },
          ],
        };

        const response = await listHeavenlymatchApprovedCandidatesPaginated(
          currentPage,
          10,
          defaultFilters
        );
        setDataSource(response);
      } catch (err) {
        throw Error(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    getUserDetails();
  }, [currentPage]);

  const handleSearch = async (e) => {
    setIsSearch(true);
    const searchValue = e.target.value?.trim()

    // Clear the previous timeout if it exists
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    // Set a new timeout
    debounceTimeout.current = setTimeout(async () => {
      const searchFields = [
        'displayName', 
        'email', 
        'firstName', 
        'lastName', 
        'username', 
        'phoneNumber'
      ];
      
      const customFilters = searchValue
        ? {
            or: searchFields.map((field) => ({
              [field]: { contains: searchValue },
            })),
          }
        : {};

      setIsLoading(true);
      const searchWithDefault = listHeavenlymatchApprovedCandidatesPaginated(
        currentPage,
        10,
        defaultFilters
      );
      const searchWithCustom =
        searchHeavenlymatchApprovedCandidatesPaginated(customFilters);
      // Call the API after debounce delay
      const response = await (searchValue?.trim() === ''
        ? (setIsSearch(false), searchWithDefault)
        : searchWithCustom);

      setDataSource(response);
      setCurrentPage(1);
      setIsLoading(false);
    }, 500); // 0.5 seconds debounce delay
  };
  return (
    <Form form={form} component={false}>
      <div id="candidateManagementTable">
        <div>
          <div
            className={`flex ${
              !mobileScreen ? 'justify-between' : 'flex-col-reverse'
            } mb-2`}
          >
            <Input
              placeholder="Search Here"
              style={{
                width: mobileScreen ? 'auto' : 200,
              }}
              prefix={<SearchOutlined />}
              onChange={handleSearch}
            />

            {/* Filter */}
            <div className="flex gap-1">
              <div id="actionButtons">
                <Popover
                  placement="bottom"
                  content={content}
                  trigger="click"
                  open={open}
                  onOpenChange={handleOpenChange}
                >
                  <Button
                    type="primary"
                    className="flex items-center justify-center bg-lightburgundy rounded-full hover:bg-black"
                    icon={<FunnelPlotOutlined />}
                  >
                    {filterTitle === null ? FILTER_LABEL : filterTitle}
                  </Button>
                </Popover>
              </div>

              {currentUserGroup?.includes(GroupType.SUPER_ADMIN) ? (
                <Button
                type="primary"
                shape="round"
                className="bg-lightburgundy"
                icon={<PlusOutlined style={{ verticalAlign: '1px' }} />}
                onClick={handleClick}
                style={{
                  width: mobileScreen ? 150 : 'auto',
                  marginBottom: mobileScreen ? 15 : '',
                  alignSelf: mobileScreen ? 'right' : 'flex-end', // merge the logic
                }}
                >
                  Invite Candidate
                </Button>
              ) : (
                ''
              )}
            </div>
          </div>
          <InviteCandidateModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            title={'Invite Candidate'}
          />
          <HeavenlyMatchTable
            columns={mergedColumns}
            dataSource={
              isPaymentFilter
                ? paymentFilteredDataSource
                : isSearchFilter
                ? serachFilteredDataSource
                : dataSource
            }
            rowClassName="editable-row heaven-red"
            loading={isLoading}
            count={count}
            isCountLoading={isCountLoading}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            isSearch={isSearch}
          />
        </div>
      </div>
    </Form>
  );
};

export default CandidateManagementTable;
